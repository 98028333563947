<template>
  <div v-show="navigation">
    <v-menu
      transition="slide-x-transition"
      style="z-index: 1001"
      :disabled="$store.getters.placesLoading"
      offset-y
      location="bottom"
      content-class="dropdown-menu"
      :close-on-content-click="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          @click="handleClickFilterButton"
          elevation="0"
        >
          <CategoryIcon />
        </v-btn>
      </template>
      <v-list
        style="max-height: 600px; text-transform: capitalize"
        class="overflow-y-auto"
      >
        <div v-if="dialog === false">
          <div v-for="(item, index) in categories" :key="index">
            <v-list-item class="cursor-pointer">
              <v-list-item-content>
                <v-list-item-title
                  @click="clicked(item)"
                  @click.stop.prevent
                  class="d-flex flex-row justify-start align-item-center"
                >
                  <div>
                    <img
                      :src="item.icon"
                      :alt="item.name"
                      class="mr-2 ml-2"
                      width="38"
                      height="38"
                      :style="{ 'object-fit': 'contain' }"
                    />
                  </div>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
              <div>
                <v-icon>
                  {{
                    $vuetify.rtl ? "mdi-chevron-left" : "mdi-chevron-right"
                  }}</v-icon
                >
              </div>
            </v-list-item>
            <v-divider :key="index + 1"></v-divider>
          </div>
        </div>

        <div v-else class="position-relative">
          <div class="d-flex flex-row align-center justify-space-between">
            <v-btn
              color="blue darken-1 flex-grow-1"
              text
              @click="dialog = false"
              @click.stop.prevent
            >
              <v-icon size="30px"> mdi-chevron-left </v-icon>
            </v-btn>
            <div class="flex-grow-1 text-center">
              <span class="text-subtitle-1 mr-15 font-weight-bold">{{
                selectedCategory.name
              }}</span>
            </div>
          </div>

          <div v-for="(store, index) in categoriesStore" :key="index">
            <v-list-item @click="clickedStore(store)">
              <v-list-item-content>
                <v-list-item-title>{{ store.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  <div
                    class="d-flex flex-row align-item-center justify-content-center"
                  >
                    <span>{{
                      renderFloorData(store.location.properties.floorLevel)
                    }}</span>
                    {{ store.category ? "," : "" }}
                    <span class="ml-1"
                      >{{ store.category ? store.category.name : "" }}
                    </span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="store.id + 1"></v-divider>
          </div>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { postAnalysesDataAsync } from "../services/services";
import CategoryIcon from "@/components/icons/CategoryIcon";

export default {
  //Top right corner category button component
  name: "CategoryButton",
  components: {
    CategoryIcon,
  },
  data() {
    return {
      categoriesStore: [],
      dialog: false,
      selectedCategory: null,
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const navigationConfig = this.$store.getters.placeConfig.navigation;

      if (paramsNavigationConfig === false && navigationConfig === true)
        return false;
      return navigationConfig;
    },
  },
  methods: {
    async handleClickFilterButton() {
      this.dialog = false;
      this.$store.dispatch("openFloorsDropDown", false);
      await postAnalysesDataAsync({
        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),
          state: "active",
          category: null,
          poiId: null,
        },
        eventKey: "CATEGORY_BUTTON",
      });
    },

    renderFloorData(floorLevel) {
      let floorData = "";

      this.$store.getters.floors.forEach((item) => {
        if (item.level === floorLevel) {
          floorData = item.title;
        }
      });

      return floorData;
    },
    async clicked(item) {
      this.dialog = true;
      this.setCategories(item);
      this.selectedCategory = item;

      await postAnalysesDataAsync({
        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),
          state: "active",
          category: { id: item.id, name: item.name },
          poiId: null,
        },
        eventKey: "CATEGORY_BUTTON",
      });
    },
    async clickedStore(store) {
      await postAnalysesDataAsync({
        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),
          state: "active",
          category: { id: store.category.id, name: store.category.name },
          poiId: store.id,
        },
        eventKey: "CATEGORY_BUTTON",
      });
      this.$store.getters.stores.forEach((element) => {
        if (element.id === store.id) {
          let place = {
            title: element.title,
            Id: element.id,
            floor: element.location.properties.floorLevel,
            center: element.location.geometry.coordinates,
          };
          this.$store.dispatch("getStoreLocation", place);
          const shopIcon = element.category ? element.category.icon : "";
          const tags = element.tags ? element.tags : [];
          const zoneId = element.properties
            ? element.properties.zoneId
              ? element.properties.zoneId
              : ""
            : "";

          this.$store.dispatch("shopInfo", {
            title: element.title,
            category: element.category,
            icon: shopIcon,
            tags: tags,
            zoneId: zoneId,
            properties: element.properties,
            isCluster: false,
            center: element.location.properties.shopCenterPoint,
          });
        }
      });

      this.dialog = false;
    },
    //sorts stores array alphabetically by title
    sortStores(arr) {
      arr.sort(function (a, b) {
        return a.title.localeCompare(b.title, "tr");
      });
    },
    setCategories(item) {
      this.categoriesStore = [];
      this.$store.getters.stores.forEach((element) => {
        if (element.category) {
          if (
            element.category.id == item.id &&
            element.navigation.properties.isVisibleOnList
          ) {
            this.categoriesStore.push(element);
          }
        }
      });
      this.sortStores(this.categoriesStore);
    },
    hanldeOnCloseDialog() {
      if (this.dialog === false) {
        this.selectedCategory = null;
        this.categoriesStore = [];
      }
    },
  },
};
</script>

<style>
.dropdown-menu {
  left: 16px !important;
  width: 37%;
  position: absolute !important;
  top: 80px !important;
  max-width: 100%;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .dropdown-menu {
    width: 37%;
  }
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .dropdown-menu {
    width: 90%;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .dropdown-menu {
    width: 90%;
  }
}
</style>
