var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$store.getters.floors.length > 1 &&
    _vm.$store.getters.hasElevator === true &&
    _vm.$store.getters.placesLoading === false
  )?_c('section',[(_vm.hasMultiStoreIds === true && _vm.$store.getters.showClusters === true)?_c('v-btn',_vm._b({staticStyle:{"bottom":"70px"},attrs:{"color":"error","dark":"","fab":"","small":"","fixed":"","bottom":"","left":""},on:{"click":_vm.handleClearMap}},'v-btn',_vm.props,false),[_c('v-icon',[_vm._v(" mdi-broom ")])],1):_vm._e(),(_vm.navigation)?_c('div',{class:['setting-button elevation-1', _vm.disabilityPositionClass]},[_c('v-btn',{class:[
        'rounded-circle disability-btn',
        { disabled: _vm.disabled === false },
      ],attrs:{"fab":"","small":""},on:{"click":function($event){_vm.disabled = !_vm.disabled}}},[_c('ElevatorIcon',{class:_vm.disabled === true ? 'secondary--text' : 'grey--text',attrs:{"width":"23","height":"23"}})],1),_c('GiftButton'),_c('ParkingButton')],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }