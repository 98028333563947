<template>
  <section
    v-if="
      $store.getters.floors.length > 1 &&
      $store.getters.hasElevator === true &&
      $store.getters.placesLoading === false
    "
  >
    <v-btn
      color="error"
      dark
      fab
      small
      fixed
      bottom
      left
      style="bottom: 70px"
      @click="handleClearMap"
      v-bind="props"
      v-if="hasMultiStoreIds === true && $store.getters.showClusters === true"
    >
      <v-icon> mdi-broom </v-icon>
    </v-btn>

    <div
      v-if="navigation"
      :class="['setting-button elevation-1', disabilityPositionClass]"
    >
      <v-btn
        @click="disabled = !disabled"
        fab
        small
        :class="[
          'rounded-circle disability-btn',
          { disabled: disabled === false },
        ]"
      >
        <ElevatorIcon
          width="23"
          height="23"
          :class="disabled === true ? 'secondary--text' : 'grey--text'"
        />
      </v-btn>

      <GiftButton />
      <ParkingButton />
    </div>
  </section>
</template>

<script>
import { postAnalysesDataAsync } from "../services/services";
import ElevatorIcon from "./icons/ElevatorIcon.vue";
import GiftButton from "@/components/GiftButton.vue";
import ParkingButton from "@/components/ParkingButton.vue";

export default {
  //Left bottom corner settings button component
  name: "SettingsButton",
  components: {
    ElevatorIcon,
    GiftButton,
    ParkingButton,
  },
  data() {
    return {
      disabled: false,
      hasMultiStoreIds: false,
      placeId: localStorage.placeId,
      languages:
        this.placeId === "c5fd8a51-3d94-4108-85da-a7811d400ad0"
          ? [
              { label: "Türkçe", value: "tr" },
              { label: "English", value: "en" },
              { label: "عربى", value: "ar" },
            ]
          : [
              { label: "Türkçe", value: "tr" },
              { label: "English", value: "en" },
            ],
    };
  },
  watch: {
    async disabled(val) {
      await postAnalysesDataAsync({
        eventData: {
          timestamp: Math.floor(new Date().getTime() / 1000),
          // String -> acildiysa “activated”, kapandıysa “deactivated”
          state: val === true ? "activated" : "deactivated",
          route:
            this.$store.getters.routeInfo.length > 0 ? "active" : "deactive",
        },
        eventKey: "ACCESSIBILITY_BUTTON",
      });
      localStorage.setItem("disabled", val);
      const { endPoint, startPoint, floor, center } =
        this.$store.getters.startPointEndPointInfo;

      if (this.$store.getters.drawRoute.length > 0) {
        this.$store.dispatch("reset");

        this.$store.dispatch("getRoute", {
          floor: floor,
          center: center,
          startPoint: startPoint,
          endPoint: endPoint,
        });
      }
    },
  },
  methods: {
    languagesSelected(language) {
      let urlParams = new URLSearchParams(window.location.search);
      const hasLanguageParams = urlParams.has("language");

      if (hasLanguageParams) {
        const placeId = localStorage.getItem("placeId");
        localStorage.setItem("language", language.value);

        this.$router.push(`/?placeId=${placeId}&language=${language.value}`);
        this.$router.go(0);
      } else {
        localStorage.setItem("language", language.value);
        this.$router.go(0);
      }
    },
    handleClearMap() {
      this.$store.dispatch("showClusters", false);

      this.$store.dispatch("clustersList", []);
      this.hasMultiStoreIds = false;
    },
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("multiStoreIds")) {
      this.hasMultiStoreIds = true;
    } else {
      this.hasMultiStoreIds = false;
    }

    if (localStorage.getItem("disabled") === "true") {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  },
  computed: {
    placeConfig() {
      return this.$store.getters.placeConfig;
    },
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const dataNavigationConfig = this.$store.getters.navigation;

      if (paramsNavigationConfig === false && dataNavigationConfig === true)
        return false;
      return dataNavigationConfig;
    },
    disabilityPositionClass() {
      if (
        this.placeConfig.disabilityButton &&
        this.placeConfig.disabilityButton.position
      ) {
        const position = this.placeConfig.disabilityButton.position;
        return {
          "disability-btn-position-right": position === "right",
          "disability-btn-position-top-right": position === "top-right",
        };
      } else {
        return {
          "disability-btn-position-left": true,
        };
      }
    },
  },
};
</script>

<style scoped>
.setting-button {
  position: fixed;
  box-shadow: none !important;
  border-radius: 10px;
  color: black;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 35px;
}
.disability-btn-position-left {
  left: 10px;
  top: 50%;
}
.disability-btn-position-right {
  right: 20px;
  top: 50%;
}
.disability-btn-position-top-right {
  position: relative;
}
</style>
import store from "../store";
